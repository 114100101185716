//styles
import './CustomerList.css';

//hooks
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

//redux
import { getAllCustomers, deleteClient } from "../../slices/clientSlice";

//components
import Title from '../../components/Title/Title';
import Input from '../../components/Input/Input';
import DataTable from 'react-data-table-component';
import { format } from 'date-fns-tz';

const CustomerList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { customers, reloadTable } = useSelector((state) => state.client);
    const [records, setRecords] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Teléfone',
            selector: row => row.telephone,
            sortable: true
        },
        {
            name: 'Estado',
            selector: row => row.state,
            sortable: true
        },
        {
            name: 'Município',
            selector: row => row.county,
            sortable: true
        },
        {
            name: 'Código postal',
            selector: row => row.codPostal,
            sortable: true
        },
        {
            name: 'Data de nascimento:',
            selector: row => (row.dateOfBirth != null ? format(new Date(row.dateOfBirth), 'dd/MM/yyyy') : ''),
            sortable: true
        },
        {
            name: 'Encontrado por:',
            selector: row => row.foundBy,
            sortable: true
        },
        {
            name: 'Indicado por:',
            selector: row => row.indicatedBy,
            sortable: true
        },
        { 
            cell:(row) => (
                <button onClick={() => navigate("/editar-cliente/"+ row.id)} className='btn-xs btn-warning'>Editar</button>
            )
        },
        { 
            cell:(row) => (
                <button onClick={() => dispatch(deleteClient(row.id))} className='btn-xs btn-danger'>Borrar</button>
            )
        }
    ];

    const handlerFilter = (event) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        if (customers) {
            const filteredRecords = customers.filter((record) => {
                return (
                    record.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    record.telephone.toLowerCase().includes(searchTerm.toLowerCase())
                );
            });

            setRecords(filteredRecords);
        }
    }, [customers, searchTerm]);

    useEffect(() => {
        dispatch(getAllCustomers());
        if (reloadTable)
            dispatch(getAllCustomers());
    }, [dispatch, reloadTable]);

    useEffect(() => {
        setRecords(customers)
    }, [customers]);

  return (
    <div className='customer-list'>
      <div className='container'>
        <Title text='Lista Clientes' />
        <div className='table'>
            <div className='search'>
                <Input 
                    label='Buscar:'
                    type='text' 
                    method={handlerFilter}
                />
            </div>
            {customers &&
                <DataTable 
                   className='datatable'
                   columns={columns}
                   data={records}
                   fixedHeader
                   pagination
                ></DataTable>
            }
        </div>
      </div>
    </div>
  );
}

export default CustomerList;