//styles
import './ProductList.css';

//hooks
import {useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

//redux
import { getAllProducts, deleteProduct } from "../../slices/productSlice";

//components
import Title from '../../components/Title/Title';
import Input from '../../components/Input/Input';
import DataTable from 'react-data-table-component';

const ProductList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { products, reloadTable } = useSelector((state) => state.product);
    const [records, setRecords] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const columns = [
        {
            name: 'Codigo',
            selector: row => row.code,
            sortable: true
        },
        {
            name: 'Nombre',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Descripición',
            selector: row => row.description,
            sortable: true
        },
        {
            name: 'Código del Barras',
            selector: row => row.barCode,
            sortable: true
        },
        {
            name: 'Precio',
            selector: row => row.price,
            sortable: true
        },
        { 
            cell:(row) => (
                <button onClick={() => navigate("/editar-producto/"+ row.id) } className='btn-xs btn-warning'>Editar</button>
            )
        },
        { 
            cell:(row) => (
                <button onClick={() => dispatch(deleteProduct(row.id))} className='btn-xs btn-danger'>Borrar</button>
            )
        }
    ];

    const handlerFilter = (event) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        if (products) {
            const filteredRecords = products.filter((record) => {
                return (
                    record.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    record.name.toLowerCase().includes(searchTerm.toLowerCase())
                );
            });

            setRecords(filteredRecords);
        }
    }, [products, searchTerm]);


    useEffect(() => {
        dispatch(getAllProducts());
        if (reloadTable)
            dispatch(getAllProducts());
    }, [dispatch, reloadTable]);

    useEffect(() => {
        setRecords(products)
    }, [products]);

    return (
        <div className='product-list'>
        <div className='container'>
            <Title text='Lista de Productos' />
            <div className='table'>
                <div className='search'>
                    <Input 
                        label='Buscar:'
                        type='text' 
                        method={handlerFilter}
                    />
                </div>
                {products &&
                    <DataTable 
                        className='datatable'
                        columns={columns}
                        data={records}
                        fixedHeader
                        pagination
                    ></DataTable>
                }
            </div>
        </div>
        </div>
  );
}

export default ProductList;