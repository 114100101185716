import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import saleReducer from "./slices/saleSlice";
import productReducer from "./slices/productSlice";
import clientReducer from "./slices/clientSlice";
import userReducer from "./slices/userSlice";
import locationReducer from "./slices/locationSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    product: productReducer,
    client: clientReducer,
    sale: saleReducer,
    location: locationReducer
  },
});