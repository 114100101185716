//styles
import './Sidebar.css';

//icons
import { FaUserPlus } from 'react-icons/fa';
import { FaList } from 'react-icons/fa';
import { FaUsers } from 'react-icons/fa';
import { BiSolidBox } from 'react-icons/bi';
import { FaStore } from 'react-icons/fa';
import { FiMenu } from 'react-icons/fi';
import { FaUserFriends } from "react-icons/fa";

//images
import Logo from '../../assets/logo.jpg';

//utils
import { priToUpper } from '../../utils/text'; 

//hooks
import { useState } from 'react';
import { useSelector } from "react-redux";

//components
import { NavLink, useNavigate } from 'react-router-dom'; 

const Sidebar = () => {
    const navigate = useNavigate();

    const { user } = useSelector((state) => state.auth);
    const [active, setActive] = useState('enable');

    const navToggle = () => {
        active == 'disabled' 
            ? setActive('enable') 
            : setActive('disabled');
    }

  return (
    <>
        <FiMenu 
                className={'navToggle ' + active}
                onClick={navToggle}
        />
        <nav className={'sidebar ' + active}>
            <ul>
                <li className='logo'>
                    <div className='logo-container'>
                        <img src={Logo} alt="BV Home" onClick={() => navigate('/')}/>
                    </div>
                    <span> {priToUpper(user.username)} </span>
                </li>
                <li className='title-category'>
                    <h3>ADMINISTRADOR</h3> 
                </li>
                <li>
                    <NavLink to='/equipo' className={({isActive}) => (isActive ? 'active' : '')}>
                        <FaUserPlus />
                        <span>Agregar Equipo</span>                    
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/lista-equipo' className={({isActive}) => (isActive ? 'active' : '')}>
                        <FaList />
                        <span>Equipo</span>                    
                    </NavLink>
                </li>
                <li className='title-category'>
                <h3>CLIENTES</h3> 
                </li>
                <li>
                    <NavLink to='/agregar-clientes' className={({isActive}) => (isActive ? 'active' : '')}>
                        <FaUserFriends />
                        <span>Agregar Clientes</span>                    
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/lista-clientes' className={({isActive}) => (isActive ? 'active' : '')}>
                        <FaUsers />
                        <span>Clientes</span>                    
                    </NavLink>
                </li>
                <li className='title-category'>
                <h3>PRODUTOS</h3> 
                </li>
                <li>
                    <NavLink to='/agregar-producto' className={({isActive}) => (isActive ? 'active' : '')}>
                        <BiSolidBox />
                        <span>Agregar Producto</span>                    
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/lista-de-productos' className={({isActive}) => (isActive ? 'active' : '')}>
                        <FaStore />
                        <span>Lista de Productos</span>                    
                    </NavLink>
                </li>
                <li className='title-category'>
                <h3>VENTAS</h3> 
                </li>
                <li>
                    <NavLink to='/lista-ventas' className={({isActive}) => (isActive ? 'active' : '')}>
                        <FaStore />
                        <span>Lista Ventas</span>                    
                    </NavLink>
                </li>
            </ul>
        </nav>
    </>
  );
}

export default Sidebar;