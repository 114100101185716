//styles
import "./Home.css";

//icons
import { RiLogoutBoxRFill } from "react-icons/ri";
import "react-toastify/dist/ReactToastify.css";

//hooks
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//redux
import {
  getAllCustomers,
  registerClient,
  resetMessage as resetMessageClient,
  resetError as resetErrorClient,
} from "../../slices/clientSlice";
import {
  getAllStates,
  getAllCounties,
} from "../../slices/locationSlice";
import { getAllProducts } from "../../slices/productSlice";
import { registerSale, resetMessage, resetError } from "../../slices/saleSlice";
import { logout, reset } from "../../slices/authSlice";

//components
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Select from "../../components/Select/Select";
import SelectList from "../../components/SelectList/SelectList";
import { priToUpper } from "../../utils/text";
import { ToastContainer, toast } from "react-toastify";

const Home = () => {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { message, error } = useSelector((state) => state.sale);

  const [barCode, setBarCode] = useState("");
  const [quantity, setQuantity] = useState(1);

  const [activeModal, setActiveModal] = useState("");
  const [quantityEdit, setQuantityEdit] = useState("");
  const [discount, setDiscount] = useState("");
  const [idProdctEdit, setIdProdctEdit] = useState("");

  const [clientId, setClientId] = useState();
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  const [shoppingList, setShoppingList] = useState([]);

  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.product);
  const {
    customers,
    error: errorClient,
    loading: loadingClient,
    reloadTable,
    message: messageClient,
  } = useSelector((state) => state.client);
  const { states, counties } = useSelector((state) => state.location);

  const [stateList, setStateList] = useState([]);
  const [countyList, setCountyList] = useState([]);

  const [codState, setCodState] = useState("");

  const [clientName, setClientName] = useState("");
  const [clientTelephone, setClientTelephone] = useState("");
  const [clientList, setClientList] = useState([]);
  const [activeModalClient, setActiveModalClient] = useState("");

  const [dateOfBirth, setDateOfBirth] = useState("");
  const [evaluationPoints, setEvaluationPoints] = useState(0);

  const [state, setState] = useState("");
  const [county, setCounty] = useState("");
  const [codPostal, setCodPostal] = useState("");
  const [foundBy, setFoundBy] = useState("");

  const [indicatedBy, setIndicatedBy] = useState("");

  const handleLogout = () => {
    dispatch(logout());
    dispatch(reset());

    navigate("/login");
  };

  //client

  const handleStateSelectList = (states) => {
    const statesList = [];
    states.map((state) =>
      statesList.push({
        value: state.codState,
        label: state.name,
      })
    );
    setStateList(statesList);
  };

  const handleCountySelectList = (counties) => {
    const countiesList = [];
    counties.map((county) =>
      countiesList.push({
        value: county.name,
        label: county.name,
      })
    );
    setCountyList(countiesList);
  };

  const filterCountiesByState = (code) => {
    setCodState(code);

    const countiesFilterList = counties.filter(
      (county) => county.codState == code
    );

    const countiesList = [];

    countiesFilterList.map((county) =>
      countiesList.push({
        value: county.name,
        label: county.name,
      })
    );

    setCountyList(countiesList);
  };

  useEffect(() => {
    handleStateSelectList(states);
  }, [states]);

  useEffect(() => {
    handleCountySelectList(counties);
  }, [counties]);

  useEffect(() => {
    dispatch(getAllStates());
    dispatch(getAllCounties());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllCustomers());
    if (reloadTable) {
      dispatch(getAllCustomers());
    }
  }, [dispatch, reloadTable]);

  useEffect(() => {
    handleClientSelectList(customers);
  }, [customers]);

  const handleClientSelectList = (customers) => {
    const customersList = [];
    customers.map((client) =>
      customersList.push({
        value: client.id,
        label: client.name,
      })
    );
    setClientList(customersList);
  };

  const handleSubmitClient = (e) => {
    e.preventDefault();

    const client = {
      name: clientName,
      telephone: clientTelephone,
      dateOfBirth: dateOfBirth,
      state: state,
      county: county,
      codPostal: codPostal,
      foundBy: foundBy,
      evaluationPoints: parseInt(evaluationPoints),
      indicatedBy: foundBy == "Indicación" ? indicatedBy : null,
    };

    dispatch(registerClient(client));
  };

  if (messageClient) {
    toast.success(messageClient[0], {
      autoClose: 10000,
    });
    dispatch(resetMessageClient());
  }

  if (errorClient) {
    toast.error(errorClient, {
      autoClose: 10000,
    });

    dispatch(resetErrorClient());
  }

  //edit product

  const openModalClient = () => {
    setActiveModalClient("active");
  };

  const openModal = (id, discount, quantity) => {
    setActiveModal("active");
    setQuantityEdit(quantity);
    setDiscount(discount);
    setIdProdctEdit(id);
  };

  const handleEditProduct = (e) => {
    e.preventDefault();

    shoppingList.find((row) =>
      row.id == idProdctEdit
        ? ((row.quantity = quantityEdit),
          (row.price = calculateDiscount(
            quantityEdit < 0 ? -row.realPrice : row.realPrice,
            discount
          )),
          (row.discount = discount))
        : ""
    );

    setAmount(handlerTotal());

    setActiveModal("");
    setQuantityEdit("");
    setIdProdctEdit("");
    setDiscount("");
  };

  const handlerTotal = () => {
    let sumTotal = 0;
    for (let i = 0; i < shoppingList.length; i++) {
      sumTotal += parseFloat(
        shoppingList[i].price *
          (shoppingList[i].quantity < 0
            ? -shoppingList[i].quantity
            : shoppingList[i].quantity)
      );
    }
    return sumTotal.toFixed(2);
  };

  const calculateDiscount = (price, discount) => {
    if (discount > 100 || discount < 0) {
      throw alert("Número invalido.");
    } else if (discount == "") {
      return price;
    }

    const originalPriceNumber = parseFloat(price);
    const discountNumber = parseFloat(discount);

    const discountedValue =
      originalPriceNumber - originalPriceNumber * (discountNumber / 100);

    return discountedValue.toFixed(2);
  };

  //sale

  if (message) {
    toast.success(message[0], {
      autoClose: 10000,
    });
    dispatch(resetMessage());
  }

  if (error) {
    toast.error(error, {
      autoClose: 10000,
    });

    dispatch(resetError());
  }

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  const addProductToTist = (e) => {
    e.preventDefault();

    const productData = products.find((row) => row.barCode == barCode);

    if (!productData || productData.length == 0) return;

    const query = shoppingList.find((row) => row.barCode == barCode);

    if (query && query.length != 0) {
      shoppingList.find((row) =>
        row.barCode == barCode
          ? ((row.quantity = parseFloat(row.quantity) + parseFloat(quantity)),
            (row.price = calculateDiscount(row.realPrice, row.discount)))
          : null
      );

      setAmount(handlerTotal());
      setQuantity(1);
      setBarCode("");
      return;
    }

    const product = {
      id: productData.id,
      barCode: barCode,
      name: productData.name,
      description: productData.description,
      quantity: quantity,
      realPrice: productData.price,
      price: productData.price,
      discount: 0,
    };

    shoppingList.push(product);

    setAmount(handlerTotal());
    setQuantity(1);
    setBarCode("");
  };

  const removeProductToTist = (id) => {
    const listRemoveUpdate = shoppingList.filter((a) => a.id !== id);
    setShoppingList(listRemoveUpdate);
    setAmount(handlerListTotal(listRemoveUpdate));
  };

  const handlerListTotal = (list) => {
    let sumTotal = 0;
    for (let i = 0; i < list.length; i++) {
      sumTotal += parseFloat(
        list[i].price *
          (list[i].quantity < 0 ? -list[i].quantity : list[i].quantity)
      );
    }
    return sumTotal;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!clientId) return;
    if (shoppingList.length == 0) return;

    for (let i = 0; i < shoppingList.length; i++) {
      const productId = shoppingList[i].id;
      const quantityNew = parseInt(shoppingList[i].quantity);
      const price = parseFloat(shoppingList[i].price);

      const sale = {
        clientId: clientId,
        productId: productId,
        price: price,
        quantity: quantityNew,
        paymentMethod: paymentMethod,
      };

      dispatch(registerSale(sale));
    }

    setShoppingList([]);
    setAmount("");
    setPaymentMethod("");
  };

  const checkAccessLevel = (user) => {
    if (!user) return false;
    if (user.accessLevel === "admin") return true;
    return false;
  };

  return (
    <>
      <ToastContainer />
      <div className="home">
        <div className="profile">
          {checkAccessLevel(user) && (
            <div>
              <span className="menu" onClick={() => navigate("lista-ventas")}>
                DashBoard
              </span>
              |
            </div>
          )}
          <span>{user && priToUpper(user.username)}</span>
          <RiLogoutBoxRFill onClick={handleLogout} />
        </div>
        <div className={"modal-client " + activeModalClient}>
          <form onSubmit={handleSubmitClient} className="modal-container">
            {errorClient && <p className="error">{errorClient}</p>}
            <Input
              label="Nombre:"
              type="text"
              method={(e) => setClientName(e.target.value)}
              value={clientName}
            />
            <Input
              label="Telefono:"
              type="text"
              method={(e) => setClientTelephone(e.target.value)}
              value={clientTelephone}
            />
            <Input
              label="Fecha nacimiento:"
              type="date"
              method={(e) => setDateOfBirth(e.target.value)}
              value={dateOfBirth}
            />
            <Select
              label="Puntaje:"
              options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
              method={(e) => setEvaluationPoints(e.target.value)}
              value={evaluationPoints}
            />
            {stateList && (
              <SelectList
                label="Estado:"
                value={codState}
                method={(selected) => {
                  setState(selected.label);
                  filterCountiesByState(selected.value);
                }}
                list={stateList}
              />
            )}
            {!stateList && <SelectList disabled />}
            {countyList && (
              <SelectList
                label="Municipio:"
                value={county}
                method={(selected) => {
                  setCounty(selected.value);
                }}
                list={countyList}
              />
            )}
            {!countyList && <SelectList disabled />}
            <Input
              label="Codigo postal:"
              type="text"
              method={(e) => setCodPostal(e.target.value)}
              value={codPostal}
            />
            <Select
              label="Como conoció BV-Home:"
              options={[
                "",
                "Redes sociales",
                "Algún sitio de búsqueda en internet",
                "Indicación",
                "Descubrimiento físico",
              ]}
              method={(e) => setFoundBy(e.target.value)}
              value={foundBy}
            />
            {foundBy == "Indicación" && (
              <Input
                label="Referido por:"
                type="text"
                method={(e) => setIndicatedBy(e.target.value)}
                value={indicatedBy}
              />
            )}
            <div className="action">
              {!loadingClient && <Button text="Adicionar" />}
              {loadingClient && <Button text="Aguarde..." disabled />}
              <Button
                text="Cancelar"
                type="button"
                method={() => setActiveModalClient("")}
              />
            </div>
          </form>
        </div>
        <div className={"modal-edit " + activeModal}>
          <form onSubmit={handleEditProduct} className="modal-container">
            <Input
              label="Cantidad:"
              type="number"
              method={(e) => setQuantityEdit(e.target.value)}
              value={quantityEdit}
              required
            />
            <Input
              label="Descuento de:"
              type="number"
              method={(e) => setDiscount(e.target.value)}
              value={discount}
              placeholder="%"
              min="0"
              max="100"
            />
            <div className="action">
              <Button text="Editar" />
            </div>
          </form>
        </div>
        <div className="forms">
          <h1>Portal de Ventas BV-Home</h1>
          <form onSubmit={addProductToTist} className="product-form">
            <Input
              label="Agregar producto"
              type="text"
              name="barCode"
              placeholder="código de barras"
              method={(e) => setBarCode(e.target.value)}
              value={barCode}
              required
            />
            <Input
              label="Cantidad"
              name="quantity"
              type="number"
              method={(e) => setQuantity(e.target.value)}
              value={quantity}
              placeholder="0"
              required
            />
            <div className="action">
              <Button text="Agregar" />
            </div>
          </form>
          <form onSubmit={handleSubmit} className="client">
            {clientList && (
              <SelectList
                label="Cliente:"
                value={clientId}
                method={(selected) => setClientId(selected.value)}
                list={clientList}
              />
            )}
            {!clientList && <SelectList disabled />}
            <Select
              label="Método de pago:"
              name="paymentMethod"
              options={[
                "",
                "Tarjeta Crédito",
                "Tarjeta Debito",
                "Transferencia",
                "Efectivo",
              ]}
              method={(e) => setPaymentMethod(e.target.value)}
              value={paymentMethod}
              required
            />
            <Input
              label="Total:"
              name="amount"
              placeholder="0"
              type="text"
              value={amount}
              disabled
              required
            />
            <div className="action">
              <Button
                text="Nuevo cliente"
                type="button"
                method={openModalClient}
              />
              <Button text="Finalizar compra" />
            </div>
          </form>
        </div>
        <div className="table">
          <table>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Cantidad</th>
                <th>Precio</th>
                <th>Precio venta</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {shoppingList.map((shopping) => (
                <tr key={shopping.id}>
                  <td>{shopping.name}</td>
                  <td>{shopping.description}</td>
                  <td>{shopping.quantity}</td>
                  <td>$ {shopping.realPrice}</td>
                  <td>$ {shopping.price}</td>
                  <td className="action">
                    <button onClick={() => removeProductToTist(shopping.id)}>
                      Eliminar
                    </button>
                    <button
                      onClick={() =>
                        openModal(
                          shopping.id,
                          shopping.discount,
                          shopping.quantity
                        )
                      }
                    >
                      Editar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Home;
