//styles
import './AddUser.css';
import 'react-toastify/dist/ReactToastify.css';

//hooks
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

//redux
import { registerUser, resetMessage, resetSuccess } from "../../slices/userSlice";

//components
import Title from '../../components/Title/Title';
import SubTitle from '../../components/SubTitle/SubTitle';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import SelectList from '../../components/SelectList/SelectList';
import { ToastContainer, toast } from 'react-toastify';

const AddUser = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { message, error, success, loading } = useSelector((state) => state.user);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [accessLevel, setAccessLevel] = useState('seller');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        const user = {
            username: username, 
            accessLevel: accessLevel,
            password: password,
            confirmPassword: confirmPassword,
        }

        dispatch(registerUser(user));        
    };

    useEffect(() => {
      if(success){
        setUsername('');
        setAccessLevel('seller');
        setPassword('');
        setConfirmPassword('');
        dispatch(resetSuccess());
      }
    }, [dispatch, success]);

    if(message){
      toast.success(message[0], {
        autoClose: 10000,
      })
      dispatch(resetMessage());
    }

  return (
    <div className='add-user'>
      <ToastContainer />
      <div className='container'>
        <Title text='Agregar Equipo' />
        <form onSubmit={handleSubmit} autocomplete="new-password">
          <SubTitle text='Información' />
          <Input 
            label='Nombre:'
            type='text' 
            method={(e) => setUsername(e.target.value)}
            value={username}
          />
          <SelectList 
            label='Nivel:'
            value={accessLevel}
            method={(selected) => setAccessLevel(selected.value)}
            list={[
              {value: 'seller', label: 'Vendedora'},
              {value: 'admin', label: 'Administrador'}
            ]} 
          />
          <Input 
            label='Contraseña:'
            type='password' 
            method={(e) => setPassword(e.target.value)}
            value={password}
          />
          <Input 
            label='Confirme la contraseña:'
            type='password' 
            method={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
          />
          <div className='buttons'>
            {!loading && <Button text='Adicionar' />}
            {loading && (
              <Button
                text='Aguarde...'
                disabled
              />
            )}
            <Button
              text='Volver'
              type='button'
              method={() => navigate('/lista-equipo')}
            />
          </div>
          {error && <p className="error">{error}</p>}
        </form>
      </div>
    </div>
  );
}

export default AddUser;