//styles
import './UserList.css';

//hooks
import {useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

//redux
import { getAllUsers, deleteUser } from "../../slices/userSlice";

//components
import Title from '../../components/Title/Title';
import Input from '../../components/Input/Input';
import DataTable from 'react-data-table-component';
import { translateAccessLevel } from '../../utils/text';

const UserList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { users, reloadTable } = useSelector((state) => state.user);
    const [records, setRecords] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.username,
            sortable: true
        },
        {
            name: 'Nível',
            selector: row => translateAccessLevel(row.accessLevel),
            sortable: true
        },
        { 
            cell:(row) => (
                <button onClick={() => navigate("/editar-equipo/"+ row.id)} className='btn-xs btn-warning'>Editar</button>
            )
        },
        { 
            cell:(row) => (
                <button onClick={() => dispatch(deleteUser(row.id))} className='btn-xs btn-danger'>Borrar</button>
            )
        }
    ];

    const handlerFilter = (event) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        if (users) {
            const filteredRecords = users.filter((record) => {
                return (
                    record.username.toLowerCase().includes(searchTerm.toLowerCase())
                );
            });

            setRecords(filteredRecords);
        }
    }, [users, searchTerm]);

    useEffect(() => {
        dispatch(getAllUsers());
        if (reloadTable)
            dispatch(getAllUsers());
    }, [dispatch, reloadTable]);

    useEffect(() => {
        setRecords(users)
    }, [users]);

    return (
        <div className='user-list'>
        <div className='container'>
            <Title text='Lista Equipo' />
            <div className='table'>
                <div className='search'>
                    <Input 
                        label='Buscar:'
                        type='text' 
                        method={handlerFilter}
                    />
                </div>
                {users &&
                    <DataTable 
                        className='datatable'
                        columns={columns}
                        data={records}
                        fixedHeader
                        pagination
                    ></DataTable>
                }
            </div>
        </div>
        </div>
  );
}

export default UserList;